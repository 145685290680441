import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; /*Switch is now Routes*/
import Home from "./pages/home/Home"
import Provider from "./pages/provider/Provider"
import Signup from "./pages/signup/Signup"
import Login from "./pages/login/Login"
import Book from "./pages/book/Book"
import Embedded from "./embedded/Embedded"
import Business from './pages/business/Business';
import BizDevRep from 'pages/careers/biz-dev-rep-I';


import {AppProvider} from './context/AppProvider'
 
import './App.css';

function App() {
  
  return (
    <div className="App">
      <AppProvider>
      {/* <AuthProvider>
        <UserProvider> */}
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Provider" element={<Provider />} />
              {/* <Route path="/SignUp" element={<Signup />} /> */}
              {/* <Route path="/Login" element={<Login />} /> */}
              <Route path="/Book" element ={<Book />} />
              {/* <Route path="/Embedded" element ={<Embedded />} /> */}
              <Route path="/Business" element ={<Business />} />

              {/* Career Paths */}
              <Route path="/Careers/biz-dev-rep-I" element={<BizDevRep />} /> {/* Add this route */}

            </Routes>
          </Router>
        {/* </UserProvider>
      </AuthProvider> */}
      </AppProvider>
    </div>
  );
}

export default App;
